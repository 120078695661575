import React, {useEffect, useState} from "react"
import {GridColDef, GridRowModesModel} from "@mui/x-data-grid"
import {FabOrder} from "../../../interfaces/FabOrder"
import {FDataGrid} from "../../ui/elements/FDataGrid"
import {Check, HourglassEmpty, QuestionMark, Rule, TapAndPlay} from "@mui/icons-material"
import {ShippingFabOrderEditor} from "../editors/ShippingFabOrderEditor"
import {useNavigate} from "react-router-dom"
import {FCheckBox} from "../../ui/inputs/FCheckBox"
import {useGlobalApi} from "../../../contexts/GlobalApiProvider"
import {TablePageLayout} from "../../base/layout/TablePageLayout"

export const ShippingPage = () => {
    const navigate = useNavigate()
    const {
        sabcShippingFabOrderApi: {
            resetApiError,
            data: orders,
            loading: loading,
            forceRefresh: forceRefreshOrders,
            error,
            deleteItem: deleteFabOrder
        },
        customersApi: {data: customers, getItemById: getCustomer}
    } = useGlobalApi()
    const [dataLoading, setDataLoading] = React.useState<boolean>(loading)
    const [dataFiltered, setDataFiltered] = React.useState<FabOrder[]>(orders)
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({})
    const [filterStates, setFilterStates] = React.useState({
        notStarted: true,
        progress: true,
        closed: true,
        gap: true
    })
    const [columns, setColumns] = useState<GridColDef[]>([{
        field: 'id',
        headerName: '#',
        editable: false,
        hideable: true
    }])
    useEffect(() => {
        setColumns(([
            {field: 'id', headerName: '#', editable: false, hideable: true},
            {field: 'internalOrderId', headerName: 'Identifiant', editable: true, width: 200},
            {
                field: 'statusCode', headerName: 'Status', editable: true, width: 300, renderCell: (params: any) => {
                    switch (params.formattedValue) {
                        case "NSTA" :
                            return <span className={"font-bold"}><HourglassEmpty/>En attente</span>
                        case "STAR" :
                            return <span className={"font-bold"}><TapAndPlay/>En cours</span>
                        case "DONE" :
                            return <span className={"font-bold"}><Check/>Cloturé</span>
                        case "ECAR" :
                            return <span className={"font-bold"}><Rule/>Ecarts</span>
                        default :
                            return <span className={"font-bold"}><QuestionMark/>Inconnu</span>
                    }
                }
            },
            {
                field: 'customer', headerName: 'Client', editable: true, width: 550, renderCell: (params: any) => {
                    if (params.row.Lines.length == 0) return "N/A"
                    return <span>{getCustomer(params.row.Lines[0].customerId)?.label}</span>
                }
            },
        ]))
    }, [customers])
    useEffect(() => {
        setDataLoading(loading)
    }, [loading])
    const toggleNotStarted = () => {
        setDataLoading(true)
        setFilterStates(prevState => ({...prevState, notStarted: !prevState.notStarted}))
    }
    const toggleInProgress = () => {
        setDataLoading(true)
        setFilterStates(prevState => ({...prevState, progress: !prevState.progress}))
    }
    const toggleDone = () => {
        setDataLoading(true)
        setFilterStates(prevState => ({...prevState, closed: !prevState.closed}))
    }
    const toggleGap = () => {
        setDataLoading(true)
        setFilterStates(prevState => ({...prevState, gap: !prevState.gap}))
    }
    const showDetails = (id: number) => {
        navigate(`${id}`)
    }
    useEffect(() => {
        let filteredData: FabOrder[] = []
        if (filterStates.notStarted) {
            filteredData.push(...orders.filter((value) => value.statusCode == "NSTA"))
        }
        if (filterStates.progress) {
            filteredData.push(...orders.filter((value) => value.statusCode == "STAR"))
        }
        if (filterStates.closed) {
            filteredData.push(...orders.filter((value) => value.statusCode == "DONE"))
        }
        if (filterStates.gap) {
            filteredData.push(...orders.filter((value) => value.statusCode == "ECAR"))
        }
        setDataFiltered(filteredData)
        setDataLoading(false)
    }, [filterStates, orders])
    return (<>
        <TablePageLayout
            title={"BON DE PRÉPARATION"}
            Editor={ShippingFabOrderEditor}
            forceRefreshData={forceRefreshOrders}
            errors={error}
            resetError={resetApiError}
            loadingReference={loading}
            checkboxs={<>
                <FCheckBox
                    label={'En attente'}
                    onChange={toggleNotStarted}
                    state={filterStates.notStarted}
                />
                <FCheckBox
                    label={'En cours'}
                    onChange={toggleInProgress}
                    state={filterStates.progress}
                />
                <FCheckBox
                    label={'Cloturés'}
                    onChange={toggleDone}
                    state={filterStates.closed}
                />
                <FCheckBox
                    label={'En Ecart'}
                    onChange={toggleGap}
                    state={filterStates.gap}
                />
            </>}
        >
            <FDataGrid
                rowModesModel={rowModesModel}
                setRowModesModel={setRowModesModel}
                rows={dataFiltered}
                loading={dataLoading}
                showMore={showDetails}
                deleteRow={deleteFabOrder}
                columns={columns}/>
        </TablePageLayout>
    </>)
}