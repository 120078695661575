import React, {FC} from "react"
import {IFModal} from "../../../ui/interfaces/elements/IFModal"
import {FModal} from "../../../ui/elements/FModal"
import {ContainerType} from "../../../../interfaces/Container"
import {Field, FormGenerator} from "../../../ui/elements/FabForm"
import {useGlobalApi} from "../../../../contexts/GlobalApiProvider"

export const ContainerTypeEditor: FC<IFModal> = ({onClose, setModalOpen}) => {
    const {
        containerTypesApi : {addItem: addContainerType, loading, error, resetApiError}
    } = useGlobalApi()
    const fields: Field[] = [
        {label: 'Nom du type', name: 'label', type: 'text', required: true},
        {label: 'Valeur RFID du type', name: 'rfidReference', type: 'text', required: true},
    ]
    const onValidAddContainerType = (data: any) => {
        const newContainerType: ContainerType = {
            id: 0,
            label: data.label,
            rfidReference: data.rfidReference
        }
        addContainerType(newContainerType).then((r) => {
            if (r && setModalOpen) setModalOpen(false)
            }
        )
    }
    return (
        <FModal title={"Ajout d'un type de contenant"}
                className="fab_editor_modal"
                canClose onClose={onClose}>
            <div className={"w-full m-auto p-1 mt-10 flex flex-col gap-5"}>
                <FormGenerator loading={loading} fields={fields} onSubmit={onValidAddContainerType}
                               apiError={error} resetApiError={resetApiError}
                />
            </div>
        </FModal>)
}
