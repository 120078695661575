import React, {FC} from "react";
import {IFModal} from "../../../ui/interfaces/elements/IFModal";
import {FModal} from "../../../ui/elements/FModal";
import {ProductReference} from '../../../../interfaces/Product';
import {Field, FormGenerator} from "../../../ui/elements/FabForm"
import {useGlobalApi} from "../../../../contexts/GlobalApiProvider"
export const ProductReferenceEditor: FC<IFModal> = ({onClose, setModalOpen}) => {
    const {
        productReferencesApi: {addItem: addProductReference, loading, error, resetApiError},
        productCategoriesApi: {data: productCategories},
        containerTypesApi: {data: containerTypes }
    } = useGlobalApi()
    const fields: Field[] = [
        {label: 'Nom du produit', name: 'label', type: 'text', required: true},
        {label: 'Référence du produit', name: 'code', type: 'text', required: true},
        {label: 'Catégorie', name: 'productCategoryId', type: 'autocomplete', required: true, data: productCategories},
        {label: 'Conditionnement', name: 'containerTypeId', type: 'autocomplete', required: true, data: containerTypes},
        {label: 'Quantité dans le conditionnement', name: 'containerUnity', type: 'number', required: true},
    ]
    const onValidAddProductReference = (data: any) => {
        const productReferenceToAdd: ProductReference = {
            id: 0,
            label: data.label,
            code: data.code,
            useBatch: true,
            rfidReference: "",
            productCategoryId: data.productCategoryId,
            containerTypeId: data.containerTypeId,
            containerUnity: data.containerUnity
        }
        addProductReference(productReferenceToAdd).then((r) => {
            if (r && setModalOpen) setModalOpen(false)
            }
        )
    }
    return (
        <FModal title={"Ajout d'une référence produit"}
                className="fab_editor_modal"
                canClose onClose={onClose}>
            <div className={"w-full m-auto p-1 mt-10 flex flex-col gap-5"}>
                <FormGenerator loading={loading} fields={fields} onSubmit={onValidAddProductReference}
                               apiError={error} resetApiError={resetApiError}/>
            </div>
        </FModal>)
}
