import React, {useState, ChangeEvent, FormEvent, useEffect} from 'react'
import {TextInput} from "../inputs/TextInput"
import {NumberInput} from "../inputs/NumberInput"
import {FAutoComplete} from "../inputs/FAutoComplete"
import {OnSelectionChangeParams} from "../interfaces/inputs/IFAutoComplete"
import {Button} from "../inputs/Button"
import {CheckCircleIcon} from "@heroicons/react/24/solid"
import {FModal} from "./FModal"
import {FCheckBox} from "../inputs/FCheckBox"

// Définition du type pour un champ de formulaire
export interface Field {
    label: string;
    name: string;
    type: string;
    required: boolean;
    data?: any[];
    onSelectionChange?: (params: OnSelectionChangeParams<any>) => void;
}

// Définition du type pour les erreurs de formulaire
interface Errors {
    [key: string]: string;
}

// Composant FormField pour gérer un champ de formulaire avec validation
const FormField: React.FC<{
    label: string;
    name: string;
    type: string;
    value: string | number | boolean;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    required: boolean;
    data?: any[];
    onSelectionChange?: (params: OnSelectionChangeParams<any>) => void
    onCheckboxChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}> = ({label, name, type, value, onChange, data, onSelectionChange, onCheckboxChange}) => (
       type == 'text' &&
            <TextInput
                key={name}
                value={value as string }
                onChange={onChange}
                dataCustom={name}
                label={label}
                className={"fab_editor_modal_input !mt-5"} />
        || type == 'number' &&
            <NumberInput
                key={name}
                value={value as number}
                onChange={onChange}
                dataCustom={name}
                label={label}
                className={"fab_editor_modal_input !mt-5"} />
        || type == 'autocomplete' && data &&
            <FAutoComplete<any>
                key={name}
                className={"fab_editor_modal_input !mt-5"}
                label={label}
                data={data}
                dataCustom={name}
                onSelectionChange={onSelectionChange!!} />
           || type == 'checkbox' &&
            <FCheckBox state={value as boolean}
                key={name}
                onChange={onCheckboxChange}
                dataCustom={name}
                border
                className={`fab_editor_modal_input !w-full`}
                label={<b>{label}</b>}/>
        || <></>
)

// Composant FormGenerator pour générer le formulaire et gérer les erreurs
export const FormGenerator: React.FC<{
    fields: Field[]
    onSubmit: (data: { [key: string]: string }) => void
    loading?: boolean
    apiError: Error[] | null
    resetApiError?: () => void
    onChangeOverload?: (name: string, newValue: string) => void
}> = ({fields, onSubmit, loading, apiError, resetApiError, onChangeOverload}) => {
    const [formData, setFormData] = useState<{ [key: string]: string } >({});
    const [errors, setErrors] = useState<Error[]>([])

    // Fonction pour mettre à jour les valeurs des champs
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { value} = e.target
        const name = e.target.getAttribute('data-custom') ?? ""
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }))
        if (onChangeOverload) onChangeOverload(name, value)
    }
    const onSelectionChange = (params: OnSelectionChangeParams<any>) => {
        const name = params.dataCustom ?? ""
        setFormData((prevState) => ({
            ...prevState,
            [name]: params.newValue.id,
        }))
        if (onChangeOverload) onChangeOverload(name, params.newValue.id.toString())
    }
    const onCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { value} = e.target
        const name = e.target.getAttribute('data-custom') ?? ""
        const oldValue = formData[name]
        const newValue = oldValue == 'on' ? '' : 'on'
        setFormData((prevState) => ({
            ...prevState,
            [name]: newValue,
        }))
        if (onChangeOverload) onChangeOverload(name, newValue)
    }
    // Fonction de validation
    const validate = (): Error[] => {
        if (!formData) return []
        const newErrors: Error[] = []
        fields.forEach(({name, label, required, type}) => {
            if (required) {
                if (type == 'number' && formData[name] == '0') {
                    newErrors.push(new Error(`${label} doit être supérieur à 0.`))
                } else if (!formData[name])
                {
                    newErrors.push(new Error(`${label} est obligatoire.`))
                }

            }
        })
        return newErrors
    }
    useEffect(() => {
        if(!apiError) return
        setErrors(apiError)
    }, [apiError])
    const onCloseModal = (e: React.MouseEvent) => {
        if (resetApiError) resetApiError()
        setErrors([])
    }
    // Fonction de soumission du formulaire
    const handleSubmit = (e: FormEvent) => {
        e.preventDefault()
        const validationErrors = validate()
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors)
        } else {
            setErrors([])
            onSubmit(formData!!) // Soumission du formulaire si tout est valide
        }
    }

    return (
        <><form>
            {
                formData && fields.map((field) => (
                    <FormField
                        key={field.name}
                        {...field}
                        value={formData[field.name]}
                        onChange={handleChange}
                        onSelectionChange={onSelectionChange}
                        onCheckboxChange={onCheckboxChange}
                    />
                ))
            }
            <Button leftIcon={<CheckCircleIcon/>} onClick={handleSubmit} loading={loading} text={"Valider la création"}
                    className={"mt-2 mb-1 w-2/12"}/>
        </form>
            {errors && errors.length > 0 &&
                <FModal
                    className="bg-white w-3/12 rounded-md m-auto flex flex-col justify-center text-center shadow-2xl !mt-5"
                    canClose onClose={onCloseModal} title={"Erreurs"}>
                    {
                        errors.map(error => (
                            <p className="p-2 m-2 bg-fab_congress-blue-200 rounded-md border-2 shadow-md border-red-500">{error.message}</p>
                        ))
                    }
                </FModal>}
        </>
)
}