import {IFModal} from "../../ui/interfaces/elements/IFModal"
import React, {FC, MouseEventHandler, useEffect, useState} from "react"
import {FabOrder} from "../../../interfaces/FabOrder"
import {useFabApp} from "../../../hooks/useFabApp"
import {FModal} from "../../ui/elements/FModal"
import {NumberInput} from "../../ui/inputs/NumberInput"
import {FAutoComplete} from "../../ui/inputs/FAutoComplete"
import {ProductReference} from "../../../interfaces/Product"
import {OnSelectionChangeParams} from "../../ui/interfaces/inputs/IFAutoComplete"
import {Button} from "../../ui/inputs/Button"
import {CheckCircleIcon, PlusCircleIcon, XCircleIcon} from "@heroicons/react/24/solid"
import {useSabcShippingFabOrderApi} from "../sabcApi"
import {Visibility} from "@mui/icons-material"
import {Field, FormGenerator} from "../../ui/elements/FabForm"
import {useGlobalApi} from "../../../contexts/GlobalApiProvider"
import {useNavigate} from "react-router-dom"

export const ShippingFabOrderEditor: FC<IFModal> = ({setModalOpen}) => {
    const {
        customersApi: {data: customers},
        productReferencesApi: {data: productReferences, getItemById: getProductReference},
        containerTypesApi: {getItemById: getContainerType},
        sabcShippingFabOrderApi: {loading, addItem: addFabOrder, error, setCustomError, resetApiError}
    } = useGlobalApi()
    const {companyChosen, siteChosen} = useFabApp()
    const [creationDone, setCreationDone] = useState<boolean>(false)
    const [newFabOrder, setNewFabOrder] = useState<FabOrder>({} as FabOrder)
    const [confirmWarnings, setConfirmWarnings] = useState<String[]>([])
    useEffect(() => {
        newOrder()
    }, [])
    const navigate = useNavigate()
    const onClose: MouseEventHandler<HTMLButtonElement> = (e) => {
        if (setModalOpen) {
            setModalOpen(false)
        }
        if (e.currentTarget.id.startsWith('order-')) {
            let id = e.currentTarget.id.replace('order-', '')
            navigate(id)
        }
    }
    const onCloseWarnings: MouseEventHandler<HTMLButtonElement> = (e) => {
        setConfirmWarnings([])
    }
    const newOrder = () => {
        setNewFabOrder({
            id: 0,
            internalOrderId: "",
            closed: false,
            siteId: siteChosen!.id,
            statusCode: "",
            Infos: [],
            Lines: [{
                id: 0,
                quantity: 0,
                quantityDone: 0,
                quantityContainer: 0,
                quantityContainerDone: 0,
                batch: "",
                productCategoryId: 0,
                productReferenceId: 0,
                containerTypeId: 0,
                customerId: 0,
                fabOrderId: 0,
                Movements: []
            },
                {
                    id: 0,
                    quantity: 0,
                    quantityDone: 0,
                    batch: "",
                    quantityContainer: 0,
                    quantityContainerDone: 0,
                    productCategoryId: 0,
                    productReferenceId: 0,
                    containerTypeId: 0,
                    customerId: 0,
                    fabOrderId: 0,
                    Movements: []
                }]
        })
        setCreationDone(false)
    }

    const fields: Field[] = [
        {label: 'Client / Destinataire', name: 'customerId', type: 'autocomplete', required: true, data: customers},
    ]

    const onProductReferenceChangeInLine = (params: OnSelectionChangeParams<ProductReference>) => {
        let dataIndex = params.dataCustom
        if (dataIndex !== undefined && dataIndex !== "") {
            setNewFabOrder((prevState) => {
                let intId = Number.parseInt(dataIndex!) + 1
                const updatedLines = [...prevState.Lines]
                updatedLines[intId] = {...updatedLines[intId], productReferenceId: params.newValue?.id ?? 0}
                return {
                    ...prevState,
                    Lines: updatedLines
                }
            })
        }
    }

    const onQuantityLineChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()
        let dataIndex = e.currentTarget.getAttribute("data-custom") ?? ""
        let qty = Number.parseInt(e.target.value)
        if (dataIndex !== "") {
            let intId = Number.parseInt(dataIndex) + 1
            setNewFabOrder((prevState) => {
                const updatedLines = [...prevState.Lines]
                updatedLines[intId] = {...updatedLines[intId], quantity: qty}
                return {
                    ...prevState,
                    Lines: updatedLines
                }
            })
        }
    }

    const addLineToOrder = () => {
        setNewFabOrder((prevState) => {
            const updatedLines = [...prevState.Lines]
            updatedLines.push({
                Movements: [],
                batch: "",
                containerTypeId: 0,
                customerId: 0,
                fabOrderId: 0,
                id: 0,
                productCategoryId: 0,
                productReferenceId: 0,
                quantity: 0,
                quantityDone: 0,
                quantityContainer: 0,
                quantityContainerDone: 0
            })
            return {
                ...prevState,
                Lines: updatedLines
            }
        })
    }
    const onValidConfirm = () => {
        addFabOrder(newFabOrder).then(r => {
            if (!r) return
            console.log(r)
            setNewFabOrder(r)
            setConfirmWarnings([])
            setCreationDone(true)
        })
    }
    const handleFormSubmit = (data: any) => {
        let fabOrderToSend = newFabOrder
        let localErrors: Error[] = []
        let localWarnings: String[] = []
        fabOrderToSend.siteId = siteChosen!.id
        fabOrderToSend.Lines[0].customerId = data.customerId
        const linesForVerifications = newFabOrder.Lines.slice(1)
        linesForVerifications.forEach((line, index) => {
            let valid = true
            if (line.productReferenceId == 0) {
                localErrors.push(new Error(`La ligne ${index + 1} n\'a pas de référence produit`))
                valid = false
            }
            if (line.quantity == 0) {
                localErrors.push(new Error(`La ligne ${index + 1} n\'a pas de de quantité`))
                valid = false
            }
            if (valid) {
                console.log("VALID")
                let product = getProductReference(line.productReferenceId)
                console.log(product)
                if (product)
                {
                    let container = getContainerType(product.containerTypeId)
                    console.log(container)
                    let containerQuantity = Math.ceil(line.quantity / product.containerUnity)
                    let rest = line.quantity % product.containerUnity
                    if (rest > 0) {
                        console.log("PUSH")
                        localWarnings.push(`Le nombre de produits de la ligne ${index + 1} n'est pas en adéquation avec son conditionnement. Il sera prévu ${containerQuantity} ${container!!.label}(s)`)
                    } else {
                        newFabOrder.Lines[index].quantityContainer = containerQuantity
                    }
                }
            }
        })
        if (localErrors.length > 0) {
            setCustomError(localErrors)
        } else if (localWarnings.length > 0) {
            setConfirmWarnings(localWarnings)
        } else {
            setNewFabOrder(fabOrderToSend)
            onValidConfirm()
        }
    }

    return (
        <FModal
            className="fab_editor_modal h-2/3"
            canClose onClose={onClose} title={`Création d'un ordre de fabrication`}>
            <div className={"w-full h-full m-auto p-10 overflow-x-scroll"}>
                <div className="w-full">
                    <span>Société : <b>{companyChosen?.label}</b></span>
                </div>
                <div className="w-full">
                    <span>Site : <b>{siteChosen?.label}</b></span>
                </div>

                {creationDone ? <div className={"w-1/2 m-auto mt-10 text-center"}>
                        <span className={"text-2xl font-bold"}>{newFabOrder.internalOrderId} crée</span>
                        <Button onClick={onClose} leftIcon={<Visibility/>} loading={loading}
                                text={"Voir"}
                                id={`order-${newFabOrder.id}`}
                                className={"mt-2 mb-1 w-full"}/>
                        <Button onClick={newOrder} leftIcon={<PlusCircleIcon/>} loading={loading}
                                text={"Ajouter un bon de préparation"}
                                className={"mt-2 mb-1 w-full"}/>
                        <Button onClick={onClose} leftIcon={<XCircleIcon/>} loading={loading}
                                text={"Fermer"}
                                className={"mt-2 mb-1 w-full"}/>
                    </div>
                    :
                    <>
                        <div className={"w-full m-auto p-1 mt-10"}>
                            <FormGenerator loading={loading} fields={fields} onSubmit={handleFormSubmit}
                                           apiError={error} resetApiError={resetApiError}/>
                        </div>
                        <div className={"w-full"}>
                            {newFabOrder.Lines && newFabOrder.Lines.length > 1 &&
                                newFabOrder.Lines.slice(1).map((line, index) => (
                                    <div className={"border-b-2 pb-3 mt-3 flex"} key={index}>
                                        <FAutoComplete<ProductReference>
                                            className={"fab_editor_modal_input"}
                                            label={"Réference produit"}
                                            data={productReferences}
                                            dataCustom={index.toString()}
                                            onSelectionChange={onProductReferenceChangeInLine}
                                        />
                                        <NumberInput onChange={onQuantityLineChange}
                                                     value={newFabOrder.Lines[index + 1].quantity}
                                                     dataCustom={index.toString()}
                                                     label={"Quantité"}
                                                     className={"fab_editor_modal_input"}/>
                                    </div>
                                ))
                            }
                        </div>
                        <Button text={"Ajouter une Ligne"} onClick={addLineToOrder}
                                leftIcon={<PlusCircleIcon className="h-12 w-12 p-2"/>}/>
                    </>
                }
            </div>
            {confirmWarnings && confirmWarnings.length > 0 &&
                <FModal
                    className="bg-white w-3/12 rounded-md m-auto flex flex-col justify-center text-center shadow-2xl !mt-5"
                    canClose onClose={onCloseWarnings} title={"Attention"}>
                    {
                        confirmWarnings.map(warning => (
                            <p className="p-2 m-2 bg-fab_congress-blue-200 rounded-md border-2 shadow-md border-red-500">{warning}</p>
                        ))
                    }
                    <div className="w-full flex">
                        <Button onClick={onCloseWarnings} leftIcon={<XCircleIcon/>} loading={loading}
                                text={"Annuler"}
                                className={"mt-2 mb-1 w-1/3"}/>
                        <Button onClick={onValidConfirm} leftIcon={<CheckCircleIcon/>} loading={loading}
                                text={"Valider"}
                                className={"mt-2 mb-1 w-1/3"}/>
                    </div>
                </FModal>}
        </FModal>)
}