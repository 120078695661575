import {FDataGrid} from "../../ui/elements/FDataGrid"
import React, {useEffect, useState} from "react"
import {GridColDef, GridRowModesModel, GridRowParams} from "@mui/x-data-grid"
import {useGlobalApi} from "../../../contexts/GlobalApiProvider"
import {TablePageLayout} from "../layout/TablePageLayout"
import {Check, HourglassEmpty, Inventory, LocalShipping, QuestionMark, Rule, TapAndPlay} from "@mui/icons-material"
import {Button} from "../../ui/inputs/Button"
import {useNavigate} from "react-router-dom"

export const SiteMovementsPage = () => {
    const navigate = useNavigate()
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({})
    const {
        fabOrdersApi: {getItemById: getOrder, forceRefresh: forceRefreshOrders, data: orders},
        siteMovementsApi: {forceRefresh, data: movements, error, resetApiError, loading}
    } = useGlobalApi()
    const [columns, setColumns] = useState<GridColDef[]>([{
        field: 'id',
        headerName: '#',
        editable: false,
        hideable: true
    }])

    useEffect(() => {
        forceRefreshOrders()
        forceRefresh()
    }, [])
    useEffect(() => {
        if (orders.length == 0) return
        if (movements.length == 0) return
        console.log(movements)
        setColumns([
            {field: 'id', headerName: '#', width: 50},
            {
                field: 'Tag',
                headerName: 'EPC',
                width: 200,
                valueGetter: (params: any) => params ? params.rfidValue : ''
            },
            {
                field: 'tagStatusCode', headerName: 'Statut Contenant', width: 200, renderCell: (params: any) => {
                    switch (params.formattedValue) {
                        case "PROD" :
                            return <span className={"font-bold"}><Inventory/>Produit</span>
                        case "EXPE" :
                            return <span className={"font-bold"}><LocalShipping/>Expedié</span>
                        case "DISP" :
                            return <span className={"font-bold"}><HourglassEmpty/>Disponible</span>
                        case "ECAR" :
                            return <span className={"font-bold"}><Rule/>Ecarts</span>
                        default :
                            return <span className={"font-bold"}><QuestionMark/>Inconnu</span>
                    }
                }
            },
            {
                field: 'orderStatusCode', headerName: 'Statut Ordre', width: 200, renderCell: (params: any) => {
                    switch (params.formattedValue) {
                        case "NSTA" :
                            return <span className={"font-bold"}><HourglassEmpty/>En attente</span>
                        case "STAR" :
                            return <span className={"font-bold"}><TapAndPlay/> En cours</span>
                        case "DONE" :
                            return <span className={"font-bold"}><Check/>Cloturé</span>
                        default :
                            return <span className={"font-bold"}><QuestionMark/>Inconnu</span>
                    }
                }
            },
            {
                field: 'action',
                headerName: 'Ordre',
                type: 'actions',
                cellClassName: 'actions',
                editable: true,
                width: 300,
                getActions: (params: any) => {
                    const order = getOrder(params.row.fabOrderId)
                    return [<Button
                        text={order?.internalOrderId ?? "N/A"}
                        disabled={!order}
                        onClick={() => {
                            if (order?.internalOrderId.startsWith('BP')) {
                                navigate(`/dashboard/shipping/${params.row.fabOrderId}`)
                            } else if (order?.internalOrderId.startsWith('OF')) {
                                navigate(`/dashboard/production/${params.row.fabOrderId}`)
                            }

                        }}
                    />]
                }
            },
            {
                field: 'User',
                headerName: 'Utilisateur',
                width: 450,
                valueGetter: (params: any) => params ? params.mobileLogin : ''
            },
        ])
    }, [movements, orders])
    return (
        <>
            <TablePageLayout
                title={"MOUVEMENTS"}
                forceRefreshData={forceRefresh}
                errors={error}
                resetError={resetApiError}
                loadingReference={loading}
            >
                <FDataGrid
                    disableEdit
                    rowModesModel={rowModesModel}
                    setRowModesModel={setRowModesModel}
                    rows={movements}
                    loading={loading}
                    columns={columns}/>
            </TablePageLayout>
        </>
    )
}