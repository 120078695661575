import {FModal} from "../../../ui/elements/FModal";
import React, {FC, useState} from "react";
import {IFModal} from "../../../ui/interfaces/elements/IFModal";
import {User} from "../../../../interfaces/User";
import {ISite} from "../../../../interfaces/ISite";
import {Field, FormGenerator} from "../../../ui/elements/FabForm"
import {useGlobalApi} from "../../../../contexts/GlobalApiProvider"

export const UserEditor: FC<IFModal> = ({onClose, setModalOpen}) => {
    const {
        rolesApi: {data: roles},
        usersApi:{addItem: addUser, loading, error, resetApiError},
        companiesApi:{data: companies},
        sitesApi:{data: sites},
    } = useGlobalApi()
    const [sitesFromCompany, setSitesFromCompany] = useState<ISite[]>([]);
    const onChangeOverLoad = (name: string, newValue: string) => {
        if (name == 'companyId') {
            const companyIdInt = Number.parseInt(newValue)
            if (companyIdInt > 0) {
                const sitesFiltered = sites.filter(site => site.companyId == companyIdInt ?? 0);
                console.log(sitesFiltered)
                setSitesFromCompany(sitesFiltered);
            }
        }
    }
    const fields: Field[] = [
        {label: 'Nom complet', name: 'fullName', type: 'text', required: true},
        {label: 'E-mail', name: 'email', type: 'text', required: false},
        {label: 'Identifiant WEB', name: 'login', type: 'text', required: true},
        {label: 'Mot de passe WEB', name: 'password', type: 'text', required: true},
        {label: 'Identifiant MOBILE', name: 'mobileLogin', type: 'text', required: true},
        {label: 'Mot de passe MOBILE', name: 'mobilePassword', type: 'text', required: true},
        {label: 'Rôle', name: 'roleId', type: 'autocomplete', required: true, data: roles},
        {label: 'Société', name: 'companyId', type: 'autocomplete', required: true, data: companies},
        {label: 'Site', name: 'siteId', type: 'autocomplete', required: true, data: sitesFromCompany},
    ]

    const onValidUser = (data: any) => {
        const userToSend: User = {
            id: 0,
            fullName: data.fullName,
            email: data.email,
            login: data.login,
            password: data.password,
            mobileLogin: data.mobileLogin,
            mobilePassword: data.mobilePassword,
            roleId: data.roleId,
            siteId: data.siteId,
            companyId: data.companyId
        }
        addUser(userToSend).then(r => {
            if (setModalOpen) setModalOpen(false);
        });
    };
    return (<FModal title={"Ajout d'un utilisateur"}
                    className="fab_editor_modal"
                    canClose onClose={onClose}>
            <div className={"w-full m-auto p-1 mt-10 flex flex-col gap-5"}>
                <FormGenerator loading={loading} fields={fields} onSubmit={onValidUser}
                               apiError={error} resetApiError={resetApiError}
                               onChangeOverload={onChangeOverLoad}
                />
            </div>
        </FModal>
    );

};