import {
    ArrowsRightLeftIcon,
    BuildingOfficeIcon,
    MapPinIcon,
    PresentationChartLineIcon
} from "@heroicons/react/24/solid";
import {useFabApp} from "../../../hooks/useFabApp";
import React from "react";
import useLocalStorage from "../../../hooks/useLocalStorage";
import {FNavButton} from '../../ui/inputs/FNavButton';
import {
    Category,
    DeveloperMode,
    Inventory2,
    Key, LocalShipping,
    LogoutOutlined,
    Person, PrecisionManufacturing,
    Store
} from "@mui/icons-material";
import {GridMenuIcon} from "@mui/x-data-grid";
import {Button} from "../../ui/inputs/Button";
import {NavSection} from "./NavSection";


export const NavBar = () => {
    const {siteChosen, setSiteChosen, setIsAuth, user} = useFabApp();
    const [, setLocalTokenItem] = useLocalStorage('fabToken', "");
    const resetSite = () => {
        setSiteChosen(null);
    };
    const logout = () => {
        setLocalTokenItem("");
        setIsAuth(false);
    };
    return (
        <div
            className={"fab_navbar"}>
            <div className="">
                <div className="w-full flex mt-1 justify-around">
                    <div
                        className="p-2.5 w-16 h-16 bg-fab_congress-blue-800 rounded-full flex flex-col border-2 border-white text-center shadow-xl relative">
                        <span
                            className=" text-3xl m-auto opacity-100 text-white">
                            {user?.fullName?.charAt(0) ?? user?.login?.charAt(0)}
                        </span>
                    </div>
                </div>
                <div className="w-full m-auto pr-5 pl-5 text-white gap-2">
                    <div className="h-full text-black flex-1 rounded-lg m-auto">
                        <b>Utilisateur : </b>{user?.fullName ?? user?.login} <br/>
                        <b>Rôle : </b> {user?.Role?.label}
                    </div>
                    <Button text="Déconnexion" leftIcon={<LogoutOutlined/>} className={"h-1/2 w-full"} fullStyle
                            onClick={logout}/>
                    {/*<div onClick={logout} className="h-1/2 flex-1 bg-fab_congress-blue-500 text-center m-auto fab_button"><p>Déconnexion</p></div>*/}
                </div>
                <div className="text-center w-10/12 m-auto flex align-middle mt-2 ">
                    <span
                        className="m-auto pr-2 pl-2 h-1/2 w-1/2 rounded-lg text-black">Site : </span>
                    <Button onClick={resetSite} fullStyle text={siteChosen ? siteChosen.label : "Aucun"}/>
                </div>
            </div>
            <div className="w-full m-auto h-2 bg-fab_congress-blue-500 mt-2 mb-2"/>
            <div className="flex flex-col h-min-full w-full">
                {user != null && user.Role?.webAccess && <>
                    <FNavButton leftIcon={<PresentationChartLineIcon className="h-6 pr-2"/>} text={"Tableau de bord"}
                                route='/dashboard'/>
                </>
                }
                {user != null && user.Role?.webAccess == true && <>
                    <NavSection title="Production">
                        <FNavButton leftIcon={<PrecisionManufacturing className="h-6 pr-2"/>} text={"Fabrications"}
                                    route="production"/>
                        <FNavButton leftIcon={<LocalShipping className="h-6 pr-2"/>} text={"Expéditions"}
                                    route="shipping"/>
                        <FNavButton leftIcon={<ArrowsRightLeftIcon className="h-6 pr-2"/>} text={"Mouvements"}
                                    route="movements"/>
                    </NavSection>

                </>}
                {user != null && user.Role?.adminAccess == true && <>
                    <NavSection title="Logistique">
                        <FNavButton leftIcon={<Inventory2 className="h-6 pr-2"/>} text={"Casiers & Palettes"}
                                    route='sabc/containers'/>
                        <FNavButton leftIcon={<BuildingOfficeIcon className="h-6 pr-2"/>} text={"Sociétés"}
                                    route="companies"/>
                        <FNavButton leftIcon={<MapPinIcon className="h-6 pr-2"/>} text={"Sites"}
                                    route="sites"/>
                        <FNavButton leftIcon={<Store className="h-6 pr-2"/>} text={"Clients"}
                                    route="customers"/>
                        <FNavButton leftIcon={<Category className="h-6 pr-2"/>} text={"Catégories de produits"}
                                    route='product_categories'/>
                        <FNavButton leftIcon={<Inventory2 className="h-6 pr-2"/>} text={"Références de produits"}
                                    route='product_references'/>
                    </NavSection>

                </>}
                {
                    user != null && user.Role?.adminAccess == true && <>
                        <NavSection title="Utilisateurs">
                            <FNavButton leftIcon={<Key className="h-6 pr-2"/>} text={"Rôles"}
                                        route="roles"/>
                            {/*<FNavButton leftIcon={<Groups className="h-6 pr-2"/>} text={"Equipes"}*/}
                            {/*            route='teams'/>*/}
                            <FNavButton leftIcon={<Person className="h-6 pr-2"/>} text={"Utilisateurs"}
                                        route='users'/>
                        </NavSection>
                    </>
                }
                {
                    user != null && user.superUser == true && <>
                        <NavSection title="Paramètrages">
                            <FNavButton leftIcon={<GridMenuIcon className="h-6 pr-2"/>} text={"Type de contenant"}
                                        route='container_types'/>
                            <FNavButton leftIcon={<DeveloperMode className="h-6 pr-2"/>} text={"Gestion des terminaux"}
                                        route='devices'/>
                        </NavSection>
                    </>
                }

            </div>


        </div>
    );
};