import React, {useEffect, useState} from "react";
import {GridColDef, GridRowModesModel, ValueOptions} from '@mui/x-data-grid';
import {FDataGrid} from "../../ui/elements/FDataGrid";
import {SiteEditor} from "./editors/SiteEditor";
import {TablePageLayout} from "../layout/TablePageLayout"
import {useGlobalApi} from "../../../contexts/GlobalApiProvider"
export const SitesPage = () => {
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
    const {
        sitesApi: {forceRefresh, data: sites, loading, error, resetApiError, updateItem: updateSite, deleteItem: deleteSite, refreshItems: refreshSites},
        companiesApi: {data: companies}
    }= useGlobalApi()
    const [init, setInit] = useState<boolean>(false)
    const [columns, setColumns] = useState<GridColDef[]>([])
    const [modalAddSiteOpen, setModalAddSiteOpen] = useState<boolean>(false)
    const openAddSiteModal = () => {
        setModalAddSiteOpen(true)
    }
    useEffect(() => {
        if (init) return;
        if (companies.length <= 0) return;
        let singleSelectOpts: ValueOptions[] = []
        companies.map(company => {
            singleSelectOpts.push({label: company.label, value: company.id})
        })
        setColumns([
            { field: 'id', headerName: '#', width: 50 },
            { field: 'label', headerName: 'Nom', editable: true, width: 100},
            { field: 'companyId', headerName: 'Société', type:'singleSelect', valueOptions: singleSelectOpts, editable: true, width: 100},
            { field: 'address', headerName: 'Addresse',editable: true, width: 200},
            { field: 'zipCode', headerName: 'Code Postal',editable: true, width: 100},
            { field: 'city', headerName: 'Ville',editable: true, width: 170},
        ])
        setInit(true)
    }, [companies]);

    useEffect(() => {
        if (!modalAddSiteOpen) refreshSites().then(r => r);
    }, [modalAddSiteOpen]);
    return (
        <>
            <TablePageLayout
                title={"SITE"}
                Editor={SiteEditor}
                forceRefreshData={forceRefresh}
                errors={error}
                resetError={resetApiError}
                loadingReference={loading}
            >
                <FDataGrid
                    rowModesModel={rowModesModel}
                    setRowModesModel={setRowModesModel}
                    updateRow={updateSite}
                    deleteRow={deleteSite}
                    confirmDeleteText={"La suppression entraînera la perte de tous les ordres du site"}
                    rows={sites}
                    loading={loading}
                    columns={columns}/>
            </TablePageLayout>
        </>
    )
}